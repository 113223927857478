<template>
  <div>
    <!-- Start Header Area -->
    <Header>
    <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Breadcrump Area  -->
    <div
      class="rn-page-title-area pt--120 pb--190 bg_image dstocks-bg"
      data-black-overlay="5"
    >
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center rn-page-title pt--100">
              <!-- <h2 class="heading-title"><img src="../../assets/images/product/dstocks_logo.png" alt="" /></h2> -->
              <h2 class="heading-title" style="color:#fff;">DStocks</h2>
              <!-- <p class="text-left" style="padding-left:20px;margin-top:-40px;">빅데이터 기반 주식 정보 추천 서비스</p> -->
              <!-- <p>빅데이터 기반 주식 정보 추천 서비스</p> -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <!-- End Breadcrump Area  -->

    <div class="rn-service-details ptb--120 bg_color--1">
      <v-container>
        <v-row>
          <v-col lg="12">
            <div class="service-details-inner">
              <div class="inner">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35 ">
                  <v-col lg="6" md="6" cols="12">
                    <div class="thumb position-relative">
                      <CoolLightBox
                        :items="items"
                        :index="index"
                        @close="index = null"
                      >
                      </CoolLightBox>

                      <div class="thumbnail position-relative">
                        <div
                          v-for="(image, imageIndex) in items"
                          :key="imageIndex"
                        >
                          <img
                            class="w-100"
                            :src="image.thumb"
                            alt="About Images"
                          />
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="6" md="6" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="section-title">
                        <span class="subtitle">Our service</span>
                        <h2 class="heading-title">새로운 주식을 발견하다.</h2>
                        <p class="description">
                          빅데이터 기반 주식정보 추천 서비스를 경험해보세요!<br />
                          투자에 대한 관심은 누구에게나 있지만 고민하고 공부하면서 실행에 옮기는 사람은 많지 않습니다.
                          주로 지인의 추천이나 직관으로 투자를 수행합니다. 디스탁스는 이러한 고민에 대한 데이터를 제공하고
                          개인에 맞는 투자성향을 통해 새로운 투자처를 발견하고 유지할 수 있도록 도움을 드립니다.
                          지금 바로 앱스토어나 구글플레이 스토어에서 만나보세요.
                        </p>
                      </div>
                    </div>
                  </v-col>
                  <v-col lg="12" md="12" cols="12">
                    <div class="details mt_md--30 mt_sm--30">
                      <div class="text-center">
                        <br /><br /><br />
                        <a href="https://apps.apple.com/us/app/%EB%94%94%EC%8A%A4%ED%83%81%EC%8A%A4-dstocks/id1564693239?app=itunes&ign-mpt=uo%3D4" target="_blank"><img src="../../assets/images/about/btn_apple.png" style="width:200px;" alt="" /></a>&nbsp;&nbsp;
                        <a href="https://play.google.com/store/apps/details?id=io.xehub.dstocks" target="_blank"><img src="../../assets/images/about/btn_android.png" style="width:200px;" alt="" /></a>
                      </div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                    <v-col lg="12">
                      <div class="text-center view-more-btn mt--60 mt_sm--30">
                        <a class="btn-default" href="https://www.dstocks.ai" target="_blank">Go DStocks</a>
                      </div>
                    </v-col>
                  </v-row>
                <!-- End Single Content  -->
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="rn-service-details ptb--120 bg_color--5" style="display:none;">
      <v-container>
        <!-- Start Single Content  -->
        <v-row class="sercice-details-content align-center row--35">
          <v-col lg="6" md="6" cols="12" order="2" order-md="1">
            <div class="details mt_md--30 mt_sm--30">
              <div class="section-title">
                <h2 class="heading-title">메인추천</h2>
                <p class="description">
                  사용자의 관심데이터와 트렌드를 바탕으로 메인화면을 통해 다양한 기업을 추천해 줍니다.
                </p>
              </div>
            </div>
          </v-col>
          <v-col lg="6" md="2" cols="12" order="1" order-md="2">
            <div class="thumb position-relative">
              <img
                class="w-100" src="../../assets/images/service/service-02.png"
                alt="Service Images"
              />
            </div>
          </v-col>
          
        </v-row>
        <!-- End Single Content  -->
      </v-container>
    </div>
    
    <Footer />
  </div>
</template>

<script>
  import feather from "feather-icons";
  import Header from "../../components/header/Header";
  import Footer from "../../components/footer/Footer";
  import PricingPlan from "../../components/pricing-plan/PricingPlan";
  export default {
    components: {
      Header,
      Footer,
      PricingPlan,
    },
    data() {
      return {
        logo: require("../../assets/images/logo/logo.png"),
        items: [
          {
            thumb: require("../../assets/images/product/dstocks_site.png"),
            src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
          },
        ],
        index: null,
        listOne: [
          {
            id: 1,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 2,
            icon: "check",
            desc: `Digital Marketing going to.`,
          },
          {
            id: 3,
            icon: "check",
            desc: ` Marketing Agency passage of.`,
          },
          {
            id: 4,
            icon: "check",
            desc: ` Seo Friendly you are going.`,
          },
          {
            id: 5,
            icon: "check",
            desc: `5 PPC Campaigns the majority.`,
          },
          {
            id: 6,
            icon: "check",
            desc: `Fast-Track Your business`,
          },
        ],
      };
    },

    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
    },
  };
</script>
